import { Component, OnInit } from '@angular/core';
import { NotificationEnum, CeltNotifService } from '@celt/notif';

@Component({
    selector: 'app-mangas',
    templateUrl: './mangas.component.html',
    styleUrls: ['./mangas.component.scss'],
})
export class MangasComponent implements OnInit {

    constructor(private _notif: CeltNotifService) {
    }

    public ngOnInit(): void {
    }

    public openNotif(): void {
        console.log('toto');
        this._notif.notify(NotificationEnum.Success, 'Hello World');
        console.log('toto');

    }

}
