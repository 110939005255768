import { Component } from '@angular/core';
import { CeltSidePanelData, CeltSidePanelService } from '@celt/side-panel';
import { User, CeltUserService } from '@celt/login';
import { CeltNotifService, NotificationEnum } from '@celt/notif';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSelectChange } from '@angular/material/select';
import * as CryptoJS from 'crypto-js';

//tslint:disable
@Component({
    selector: 'side-panel-user',
    templateUrl: 'side-panel-user.component.html',
    styleUrls: ['side-panel-user.component.scss'],
})

export class SidePanelUserComponent implements CeltSidePanelData<User> {

    public data: User;
    permissions: any;
    permissionsDisplayed: boolean = false;
    appPermission: any;
    appTitle: string = '';

    editMode: number = -1;

    appsColors: string[] = ['div-blue', 'div-red', 'div-green', 'div-purple', 'div-orange'];

    constructor(public userService: CeltUserService, public sidePanel: CeltSidePanelService, public notif: CeltNotifService) {
        this.userService.retrievePermissions().then((permissions) => this.permissions = permissions).catch((e: string) => this.notif.notify(NotificationEnum.Error, e));

    }

    clearData(): void {
    }

    getData(): User {
        return undefined;
    }

    setData(user: User): void {
        this.data = user;
    }

    updateData(user: User): void {
        this.data = user;
    }

    capitalizeFirstLetter(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    displayPermissionForApp(app: any): void {
        this.permissionsDisplayed = true;
        this.appPermission = app.value;
        this.appTitle = app.key;

    }

    displayApps(): void {
        this.permissionsDisplayed = false;
        this.appPermission = undefined;
        this.appTitle = '';
    }

    formatPermission(permissionName: string, appPart: string): string {
        return `${this.appTitle}.${appPart}.${permissionName}`;
    }

    addOrRevokePermission(permissionName: string, appPart: string, event: MatButtonToggleChange): void {
        const body: {} = {
            permissionName: `${this.appTitle}.${appPart}.${permissionName}`,
            userId: this.data.id,
        };

        event.value === 'grant' ?
            this.userService.grantPermission(body)
                .then(() => {
                    this.userService.retrieveUsers();
                    this.notif.notify(NotificationEnum.Success, 'Permission Granted', `${this.appTitle}.${appPart}.${permissionName}`);
                })
                .catch((e: string) => this.notif.notify(NotificationEnum.Error, e))
            :
            this.userService.revokePermission(body)
                .then(() => {
                    this.userService.retrieveUsers();
                    this.notif.notify(NotificationEnum.Warning, 'Permission Revoked', `${this.appTitle}.${appPart}.${permissionName}`);
                })
                .catch((e: string) => this.notif.notify(NotificationEnum.Error, e));
    }

    enableEditMode(id: number): void {
        this.editMode = id;
    }

    updatePreference(pref: any, id: number): void {
        const v: string = (document.getElementById('prefInput-' + id) as HTMLInputElement).value;
        if (v !== pref.value) {
            this.userService.updatePreference({name: pref.key, value: v, userId: this.data.id}).then(() => {
                this.editMode = -1;
                this.notif.notify(NotificationEnum.Success, 'Preference Updated', `${v}`);
                this.userService.retrieveUsers();
            }).catch((e: string) => this.notif.notify(NotificationEnum.Error, e));
        } else {
            this.editMode = -1;
        }

    }

    public changeRole(event: MatSelectChange): void {
        let roleId: number = 1;
        if (event.value === 'GOD') {
            roleId = 5;
        } else if (event.value === 'ADMIN') {
            roleId = 4;
        } else if (event.value === 'VIP') {
            roleId = 3;
        } else if (event.value === 'MEMBER') {
            roleId = 2;
        } else if (event.value === 'BASIC') {
            roleId = 1;
        }

        this.userService.updateUserRole(this.data.id, roleId).then(() => {
            this.userService.retrieveUsers();
            this.notif.notify(NotificationEnum.Success, 'Role Updated', event.value);
        }).catch((e: string) => this.notif.notify(NotificationEnum.Error, e));
    }

    public deleteUserClicked(): void {
        this.userService.deleteUser(this.data.id).then(() => {
            this.notif.notify(NotificationEnum.Success, `User ${this.data.username} deleted`);
        });
    }

    public changePassword(pass: string): void {
        navigator.clipboard.writeText(pass);
        const passhashed: string = CryptoJS.SHA256(pass).toString();

        this.userService.updateUserPasswordA(this.data.id, passhashed).then(() => {
            this.notif.notify(NotificationEnum.Success, `User's password changed`);
        });
    }


}
