import { AfterViewInit, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '../../services/settings/settings.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'plex-component',
    templateUrl: 'plex.component.html',
    styleUrls: ['plex.component.scss'],
})

export class PlexComponent implements AfterViewInit, OnDestroy {

    constructor(settingsService: SettingsService) {
        settingsService.plexActive = true;
    }

    ngAfterViewInit(): void {
        document.getElementById('p-iframe-glo').hidden = false;
    }

    ngOnDestroy(): void {
        document.getElementById('p-iframe-glo').hidden = true;
    }
}
