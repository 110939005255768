import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GuardsModule } from './components/guards/guards.module';
import { MainNavModule } from './components/main-nav/main-nav.module';
import { MemoryModule } from './components/memory/memory.module';
import { GamesPageModule } from './layout/games/games-page.module';
import { LayoutComponent } from './layout/layout.component';
import { YomeruPageModule } from './layout/yomeru/yomeru-page.module';
import { SettingsService } from './services/settings/settings.service';
import { PlexModule } from './layout/plex/plex.module';
import { CeltNotifModule } from '@celt/notif';
import { CeltLoginModule } from '@celt/login';
import { CeltSidePanelModule, SidePanelConfigStore } from '@celt/side-panel';
import { SidePanelUserModule } from './components/side-panel/side-panel-user.module';
import { SidePanelUserComponent } from './components/side-panel/side-panel-user.component';

/*tslint:disable*/
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        CeltLoginModule,
        AppRoutingModule,
        GuardsModule,
        YomeruPageModule,
        PlexModule,
        GamesPageModule,

        MainNavModule,
        MemoryModule,
        CeltNotifModule.withConfig({
            animations: {
                enabled: true,
            },
            behaviour: {
                autoHide: 7000,
                stacking: 4,
            },
            position: {
                vertical: {
                    distance: 60,
                },
            },
        }),
        CeltSidePanelModule.withConfig({
            behaviour: {
                disableClosed: false,
            },
            store: [
                new SidePanelConfigStore({
                    id: 'factory',
                    component: SidePanelUserComponent,
                }),
            ],
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            },
        }),

        SidePanelUserModule,

    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (settings: SettingsService): () => Promise<boolean> => (): Promise<boolean> => settings.load(),
            deps: [SettingsService, HttpClient],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
