import { NgModule } from '@angular/core';
import { AdminGuard } from './admin-guard';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [AdminGuard],
})
export class GuardsModule {
}
