import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ResponsiveService } from "../../services/responsive/responsive.service";
import { Subscription } from "rxjs";
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: "left-nav",
    styleUrls: ["left-nav.component.scss"],
    templateUrl: "left-nav.component.html",
})

export class LeftNavComponent implements OnInit, OnDestroy {

    @ViewChild("drawer", {static: true}) public drawer: MatSidenav;

    @Input() public title: string;

    public isMobile: boolean = false;
    public opened: boolean = true;
    public buttonVisible: boolean = false;

    public subArrays: Subscription[] = [];

    constructor(public responsiveService: ResponsiveService) {
    }

    public ngOnInit(): void {
        this.subArrays.push(this.responsiveService.mobileMenuClicked.subscribe(() => {
            this.drawer.toggle();
        }));
        this.subArrays.push(this.responsiveService.isHandset$.subscribe((b: boolean) => {
            this.isMobile = b;

            if (b) {
                this.responsiveService.mobileMenuDisplay.emit(true);
            }
        }));
    }

    public ngOnDestroy(): void {
        this.responsiveService.mobileMenuDisplay.emit(false);
        this.subArrays.forEach((s: Subscription) => {
            s.unsubscribe();
        });
    }
}
