import { Component, ViewEncapsulation } from "@angular/core";
import { YomeruPageService } from "./yomeru-page.service";
import { IMangas } from "./yomeru.interface";
import { ResponsiveService } from "../../services/responsive/responsive.service";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: "yomeru-page",
    templateUrl: "yomeru-page.component.html",
    styleUrls: ["yomeru-page.component.scss"],
})

export class YomeruPageComponent {

    public mode: string = "side";
    public toto = "navigation";
    public isOpened: boolean = true;

    constructor(private _yomeruService: YomeruPageService, public responsiveService: ResponsiveService) {
    }

    public clickConnect(): void {
    }

    public clickDisconnect(): void {

    }

    public goTo(): void {

    }

}
