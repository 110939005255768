import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { IMemory, ISettings } from "./settings.interface";

@Injectable({
    providedIn: "root",
})
export class SettingsService {

    private _urlSettingsFile: string = "config/settings.json";
    private _parameters: ISettings;
    public plexActive: boolean = false;
    constructor(private _http: HttpClient) {
    }

    public getAuthUrl(): string {
        console.log(environment.back.auth);
        return environment.back.auth;
    }

    //
    // public getApiKey(key: string): string {
    //     return this._parameters.apiKeys[key];
    // }

    public getMemoryConf(): IMemory {
        return this._parameters.games.memory;
    }

    public getNoLogin(): boolean {
        return this._parameters.noLogin;
    }

    public load(): Promise<boolean> {
        return new Promise<boolean>((resolve: (v: boolean) => void, reject: (e: string) => void): void => {
            this._http.get<ISettings>(this._urlSettingsFile).toPromise().then((settings: ISettings) => {
                this._parameters = settings;
                resolve(true);
            }).catch((error: HttpErrorResponse) => {
                console.log(error);
            });
        });

    }
}
