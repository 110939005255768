import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { SettingsService } from './services/settings/settings.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { CeltUserService } from '@celt/login';

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
})
export class AppComponent {

    menuClicked: boolean = false;
    public title: string = 'app';
    url: SafeUrl = '';

    constructor(public sannitizer: DomSanitizer,
                private _localStorageService: LocalStorageService,
                public settingsService: SettingsService,
                private _ts: TranslateService,
                public userService: CeltUserService,
                private matSvg: MatIconRegistry) {
        this._ts.setDefaultLang('en');
        this._ts.addLangs(['en', 'fr']);
        this._ts.use('en');
        this._ts.currentLang = 'en';

        this.url = this.sannitizer.bypassSecurityTrustResourceUrl(`https://seya.ovh/web`);

        this._registerMatSvgIcon();
    }

    private _registerMatSvgIcon(): void {
        this.matSvg.addSvgIcon('google_drive', this.sannitizer.bypassSecurityTrustResourceUrl('../assets/icons/google-drive-icon.svg'));
    }
}
