import { Injectable } from "@angular/core";
import { SettingsService } from "../../services/settings/settings.service";


@Injectable()
export class YomeruPageService {

    private serverUrl: string = "http://localhost:8110/api/beta/scan-status-websocket";

    constructor(private _settings: SettingsService) {
    }
}
