import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { CeltUserService } from "@celt/login";

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private _userService: CeltUserService, private _router: Router) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve: (v: boolean) => void): void => {
            if (this._userService.getUser().role.id > 3) {
                resolve(true);
            } else {
                this._router.navigate(["/forbidden"]);
                resolve(false);
            }
        });
    }
}
