import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { MemoryModule } from "../../components/memory/memory.module";
import { GamesPageComponent } from "./games-page.component";
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MemoryModule,
        RouterModule.forChild([{
            component: GamesPageComponent,
            path: "",
        }]),

    ],
    exports: [GamesPageComponent],
    entryComponents: [GamesPageComponent],
    declarations: [GamesPageComponent],
    providers: [],
})
export class GamesPageModule {
}
