import { Component } from "@angular/core";
import { IMemory } from "../../services/settings/settings.interface";
import { SettingsService } from "../../services/settings/settings.service";

@Component({
    selector: "memory-component",
    styleUrls: ["memory.component.scss"],
    templateUrl: "memory.component.html",
})

export class MemoryComponent {

    public jsonConf: IMemory;
    public allCards: ICards[] = [];
    public compareArray: ICards[] = [];
    public cardCount: number = 36;
    public win: boolean = false;

    constructor(public settings: SettingsService) {
        this.jsonConf = settings.getMemoryConf();
        this.replay(this.cardCount);
    }

    public openCard(card: ICards): void {
        if (this.compareArray.length === 0) {
            this.compareArray.push(card);
            card.src = card.imgSrc;
        } else if (this.compareArray.length === 1) {
            this.compareArray.push(card);
            card.src = card.imgSrc;
            this.compareCards();
        }

    }

    public createCards(): void {
        this.jsonConf.imgUrls.forEach((url: string, index: number) => {
            for (let j: number = 0; j < 2; j++) {
                const card: ICards = {
                    id: index,
                    src: this.jsonConf.backcardUrl,
                    imgSrc: url,
                    show: true,
                };
                if (this.allCards.length < this.cardCount) {
                    this.allCards.push(card);
                }
            }

        });
        this.allCards.forEach((card: ICards, index: number) => {
            card.unique = index;
        });
        this.shuffleCards();
    }

    public shuffleCards(): void {
        for (let i: number = this.allCards.length - 1; i > 0; i--) {
            const j: number = Math.floor(Math.random() * (i + 1));
            [this.allCards[i], this.allCards[j]] = [this.allCards[j], this.allCards[i]];
        }
    }

    public compareCards(): void {
        if (this.compareArray[0].unique === this.compareArray[1].unique) {
            this.compareArray.pop();

            return;
        }
        if (this.compareArray[0].id === this.compareArray[1].id) {
            setTimeout(() => {
                this.compareArray[0].show = false;
                this.compareArray[1].show = false;
                this.compareArray = [];
                const result: ICards[] = this.allCards.filter((card: ICards) => card.show);
                if (result.length < 1) {
                    this.win = true;
                }
            }, 1000);
        } else {
            setTimeout(() => {
                this.compareArray[0].src = this.jsonConf.backcardUrl;
                this.compareArray[1].src = this.jsonConf.backcardUrl;
                this.compareArray = [];
            }, 1000);

        }
    }

    public replay(count?: number): void {
        if (count) {
            this.cardCount = count;
        }
        this.win = false;
        this.allCards = [];
        this.compareArray = [];
        this.createCards();
    }
}

interface ICards {
    id: number;
    src: string;
    imgSrc: string;
    show: boolean;
    unique?: number;
}
