import { NgModule } from '@angular/core';

import { LeftNavComponent } from './left-nav.component';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
    imports: [
        CommonModule,
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
    ],
    exports: [LeftNavComponent],
    declarations: [LeftNavComponent],
    providers: [],
})
export class LeftNavModule {
}
