import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CeltLoginGuard, CeltLoginComponent } from '@celt/login';

import { AdminGuard } from './components/guards/admin-guard';
import { LayoutComponent } from './layout/layout.component';
/* tslint:disable */
const appRoutes: Routes = [
    {
        path: 'login',
        component: CeltLoginComponent,
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [CeltLoginGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./layout/home/home-page.module').then((m) => m.HomePageModule),
            },
            {
                path: 'yomeru',
                loadChildren: () => import('./layout/yomeru/yomeru-page.module').then((m) => m.YomeruPageModule),
            },
            {
                path: 'plex',
                loadChildren: () => import('./layout/plex/plex.module').then((m) => m.PlexModule),
            },
            {
                path: 'drive',
                loadChildren: () => import('./layout/drive/drive.module').then((m) => m.DriveModule),
            },
            {
                path: 'games',
                loadChildren: () => import('./layout/games/games-page.module').then((m) => m.GamesPageModule),
            },
            {
                path: 'admin',
                loadChildren: () => import('./layout/admin/admin-page.module').then((m) => m.AdminPageModule),
                canActivate: [AdminGuard],
            },
            {
                path: 'profile',
                loadChildren: () => import('./layout/profile/profile.module').then((m) => m.ProfileModule),
            },
            {
                path: 'forbidden',
                loadChildren: () => import('./layout/errors/forbidden/forbidden-page.module').then((m) => m.ForbiddenPageModule),
            },
            {
                path: '404',
                loadChildren: () => import('./layout/errors/forbidden/forbidden-page.module').then((m) => m.ForbiddenPageModule),
            },
            {
                path: '**',
                redirectTo: '/404',
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
        ),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule {
}
