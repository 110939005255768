import { Component } from "@angular/core";

@Component({
    selector: "yomeru-page",
    templateUrl: "games-page.component.html",
    styleUrls: ["games-page.component.scss"],
})

export class GamesPageComponent {

    constructor() {
    }
}
