import { NgModule } from '@angular/core';

import { PlexComponent } from './plex.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([{
            component: PlexComponent,
            path: '',
            // {
            //     path: "comics",
            // },
        }]),
        MatProgressSpinnerModule,
    ],
    exports: [PlexComponent],
    declarations: [PlexComponent],
    providers: [],
})
export class PlexModule {
}
