import { NgModule } from '@angular/core';

import { SidePanelUserComponent } from './side-panel-user.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [
        MatTabsModule,
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatInputModule,
    ],
    exports: [SidePanelUserComponent],
    declarations: [SidePanelUserComponent],
    providers: [],
})
export class SidePanelUserModule {
}
