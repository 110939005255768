import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { TranslateModule } from "@ngx-translate/core";
import { LeftNavModule } from "../../components/left-nav/left-nav.module";
import { MemoryModule } from "../../components/memory/memory.module";
import { MangasComponent } from "./sub-components/mangas/mangas.component";
import { MangasModule } from "./sub-components/mangas/mangas.module";
import { YomeruPageComponent } from "./yomeru-page.component";
import { YomeruPageService } from "./yomeru-page.service";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MemoryModule,
        MatCardModule,
        MatListModule,
        MatExpansionModule,
        MatSidenavModule,
        MatIconModule,
        MatTooltipModule,
        LeftNavModule,
        TranslateModule,
        MangasModule,
        RouterModule.forChild([{
            component: YomeruPageComponent,
            path: "",
            children: [
                {
                    path: "",
                    pathMatch: "full",
                    redirectTo: "mangas",
                },
                {
                    component: MangasComponent,
                    path: "mangas",
                },
                // {
                //     path: "comics",
                // },
            ],
        }]),

    ],
    exports: [YomeruPageComponent],
    entryComponents: [YomeruPageComponent],
    declarations: [YomeruPageComponent],
    providers: [YomeruPageService],
})
export class YomeruPageModule {
}
