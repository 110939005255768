import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MemoryComponent } from './memory.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [MemoryComponent],
    exports: [MemoryComponent],
    entryComponents: [MemoryComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        TranslateModule,
    ],
    providers: [],
})
export class MemoryModule {
}
