import { EventEmitter, Injectable } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
        providedIn: "root",
    },
)
export class ResponsiveService {

    public mobileMenuClicked: EventEmitter<boolean> = new EventEmitter();
    public mobileMenuDisplay: EventEmitter<boolean> = new EventEmitter();
    public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(map((result) => result.matches));

    constructor(private breakpointObserver: BreakpointObserver) {
    }
}
