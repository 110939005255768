import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
    url: SafeUrl = '';

    constructor(public sannitizer: DomSanitizer) {
        this.url = this.sannitizer.bypassSecurityTrustResourceUrl(`https://seya.ovh/web`);
    }
}
