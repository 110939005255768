import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { ResponsiveService } from '../../services/responsive/responsive.service';
import { CeltUserService, CeltLoginService } from '@celt/login';

@Component({
    selector: 'main-nav',
    templateUrl: 'main-nav.component.html',
    styleUrls: ['main-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class MainNavComponent {

    public username: string = '';
    public role: number = -1;

    public mobileOn: boolean = false;
    public toDisplay: boolean = false;

    constructor(public userService: CeltUserService,
                public loginService: CeltLoginService,
                private _router: Router,
                public responsiveService: ResponsiveService) {
        this.username = this.userService.getUser().username;
        this.role = this.userService.getUser().role.id;

        this.responsiveService.isHandset$.subscribe((b: boolean) => {
            this.mobileOn = b;
        });

        this.responsiveService.mobileMenuDisplay.subscribe((b: boolean) => {
            this.toDisplay = b;
        });
    }

    public logOut(): void {
        this.loginService.logout();
    }

    public openProfile(): void {
        this._router.navigate(['/profile']);
    }

    public menuMobileClicked(): void {
        this.responsiveService.mobileMenuClicked.emit(true);
    }

}
